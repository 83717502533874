<template>
    <div class="shop_foods">
        <el-select v-model="selected" placeholder="请选择分类" @change="changeEnent(selected)">
            <el-option v-for="item in list" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id"></el-option>
        </el-select>
    </div>
</template>
<script>
import {query_shop_info_list} from '../../api/store'
export default {
    props: {
        ent_id: {type: String | Number, default: ()=>''},
        selected_id: {type: String | Number, default: ()=>''}
    },
    data() {
        return {
            list: [],
            selected: ''
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        async getList() {
            let params = {data:{ent_id: this.ent_id}}, res
            res = await query_shop_info_list(params)
            if(res && res.code == 200) {
                this.list = res.body.data
                this.selected = this.list[0].shop_id
                this.changeEnent(this.selected)
                // this.$emit('getAllShopList', res.body.data)
            } else {
                this.$message({type: 'error', message: res.msg});
            }
        },
        changeEnent(v) {
            let item = {}
            this.list.map(child=>{
                if(child.shop_id == v) {
                    item = child
                }
            })
            this.$emit('getShopInfo', v, item)
        }
    },
    watch: {
        selected_id: function(v, o) {
            this.selected = v
        }
    }
}
</script>