<template>
    <el-dialog :title="editType == 'add'? '新建发票信息': '修改发票信息'" :visible="dialogItemVisible" @close="onCancel" width="700px">
        <el-form :model="submitForm" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="开票金额">
                        <el-input v-model="submitForm.inv_amt" placeholder="开票金额" style="width: 200px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系人">
                        <el-input v-model="submitForm.name" placeholder="名字" style="width: 200px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="电话">
                        <el-input v-model="submitForm.mobile" placeholder="手机号" style="width: 200px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="地址">
                        <el-input v-model="submitForm.addr" placeholder="地址" style="width: 100%"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onCancel">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
// import areaInfo from '@/components/areaInfo'
// import shopInfo from '@/components/shopList'
// import entInfo from '@/components/entList'
import {insert_inv_info} from '../../../../api/store'
export default {
  props: {
    dialogItemVisible: {type: Boolean, default: ()=> false},
    submitFormObj: {type: Object, default: ()=>{}},
    editType: {type: String, default:()=>''}
  },
  components: {},
  data() {
      return {
          submitForm: {},
          selectedArea: {}
      }
  },
  methods: {
    initAreaForm(item) {
      let form = {}
      if(this.editType == 'edit') {
        form = JSON.parse(JSON.stringify(item))
      } else {
          form = {}
      }
      this.submitForm = form
    },
    async onSubmit() {
      this.submitForm.ent_id = this.$store.state.ent_id
      this.submitForm.user_id = this.$store.state.user_id
      let params = {data: JSON.parse(JSON.stringify(this.submitForm))}, res, msg

      if(this.editType == 'add') {
          res = await insert_inv_info(params)
          msg = '添加'
      }
      if(res && res.code == 200) {
          this.$emit('on-success', params)
          this.$message({type: 'success', message: `${msg}成功`});
      } else {
          this.$message({type: 'error', message: res.msg});
      }
    },
    onCancel() {
      this.$emit('closeDia')
    },
    // getCityInfo(v) {
    //     console.log('vvvv', v)
    //   this.selectedArea.province = v.SelectLevel.lv1
    //   this.selectedArea.city = v.SelectLevel.lv2
    //   this.selectedArea.district = v.SelectLevel.lv3
    //   this.selectedArea.area = `${v.SelectLevel.lv1.id},${v.SelectLevel.lv2.id},${v.SelectLevel.lv3.id}`

    //   this.submitForm['area'] = `${v.SelectLevel.lv1.id},${v.SelectLevel.lv2.id},${v.SelectLevel.lv3.id}`
    //   this.submitForm['area_disp'] = `${v.SelectLevel.lv1.name},${v.SelectLevel.lv2.name},${v.SelectLevel.lv3.name}`
    // },
  },
  watch: {
    submitFormObj: function(v) {
      this.initAreaForm(v)
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{ text-align: left; }
</style>