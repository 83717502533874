import { render, staticRenderFns } from "./storeInvoiceItem.vue?vue&type=template&id=01bd88c6&scoped=true&"
import script from "./storeInvoiceItem.vue?vue&type=script&lang=js&"
export * from "./storeInvoiceItem.vue?vue&type=script&lang=js&"
import style0 from "./storeInvoiceItem.vue?vue&type=style&index=0&id=01bd88c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01bd88c6",
  null
  
)

export default component.exports