<!-- // earl 2021-09-07 -->
<template>
    <div class="store_setting">
        <div class="tab_box">
            <ul class="tab_nav">
                <li @click="tabNavBox(1)" :class="{'active': active==1}"><span>订单</span></li>
                <!-- <li @click="tabNavBox(2)" :class="{'active': active==2}"><span>支出</span></li> -->
                <li @click="tabNavBox(3)" :class="{'active': active==3}"><span>发票</span></li>
            </ul>
            <div class="tab_nav_right">
                <shopListByEntId :selected_id="selected_id" :ent_id="$store.state.ent_id" @getShopInfo="getShopInfo" @getAllShopList="getAllShopList" ref="shopListByEntId"></shopListByEntId>
            </div>
        </div>
        <store-income :shop_id="shop_id" :acctDetail="acctDetail" v-if="active==1" ref="storeIncome"></store-income>
        <!-- <store-pay :shop_id="shop_id" :acctDetail="acctDetail" v-if="active==2" ref="storePay"></store-pay> -->
        <store-invoice :shop_id="shop_id" :acctDetail="acctDetail" v-if="active==3" ref="storeInvoice"></store-invoice>

    </div>
</template>
<script>

import storeIncome from '../modules/storeIncome.vue'
import storePay from '../modules/storePay.vue'
import storeInvoice from '../modules/storeInvoice.vue'
import shopListByEntId from '../../../shopList/shopListByEntId.vue'
import {query_acct_amt_detail} from '../../../../api/store'
export default{
  name: 'store',
  data() {
    return {
      active: 1,
      shop_id: '',
      acctDetail: {},
      selected_id: ''
    }
  },
  components: {storeIncome,storePay,storeInvoice, shopListByEntId},
  created() {
      
  },
  methods: {
    async getAcctDetail({shop_id_str, type, param}) {
        let shop_id = ''
        if(shop_id_str) shop_id = shop_id_str
        else shop_id = this.shop_id
        let params = {data: {ent_id: this.$store.state.ent_id, shop_id: shop_id}}
        if(type=='rep') {
            let paramsObj = JSON.parse(JSON.stringify(param))
            delete paramsObj.data.page_index
            delete paramsObj.data.page_size
            params = paramsObj
        }
      let res = await query_acct_amt_detail(params)
      if(res && res.code == 200) {
          this.acctDetail = res.body.data
      } else {
          this.$message({message: res.msg, type: 'error'})
      }
    },
    tabNavBox(v) {
        this.active = v
    },
    getAllShopList(list) {
        this.selected_id = list[0].shop_id
        let shop_id = [list[0].shop_id]
        // if(list && list.length > 0) {
        //     list.map(item=>{
        //         shop_id.push(item.shop_id)
        //     })
        // }
        this.getAcctDetail({shop_id_str: shop_id.join(',')})
    },
    getShopInfo(v, item) {
        this.shop_id = v
        if(this.active == 1) this.$refs.storeIncome.searchListBtn()
        if(this.active == 2) this.$refs.storePay.searchListBtn()
        if(this.active == 3) this.$refs.storeInvoice.searchListBtn()
        // this.getAcctDetail()
    }
  }

}
</script>
<style lang="scss" scoped>
.tab_box{display: flex; justify-content: space-between;
    .tab_nav{ display: flex;
        li{ width: 100px; border-bottom: 50px solid transparent; border-left: 50px solid transparent; border-right: 50px solid transparent; color: #fff; position: relative; color: #666;
            span{ display: flex; width: 100%; height: 50px; justify-content: center; align-items: center; box-sizing: border-box; position: absolute; left: 10%; top: 50%; font-size: 18px;}
            
        }
        li:first-child{border-left: 0; }
        li.active{ border-bottom: 50px solid #fff;
            span{ color: #e33c4b; }
        }
    }
    .tab_nav_right{

    }
}


</style>