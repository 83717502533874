<!-- // earl 2021-09-07 -->
<template>
    <div class="store_pay">
        <div class="nav_data">
            <div class="search_select">
                <form ref="searchForm" class="search_form" label-width="0px">
                    <div class="search_item">
                        <el-date-picker v-model="dataPicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="getDatePicker"></el-date-picker>
                    </div>
                    <div class="search_item">
                        <el-select v-model="searchSelected.expend_type" placeholder="全部类型" @change="searchListBtn()">
                            <el-option v-for="item in searchOptions.type" :key="item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="search_item">
                        <el-input placeholder="请输入内容" class="search_text" v-model="searchSelected.search_str" @input='searchListBtn()'>
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                </form>
            </div>

            <div class="data_box">
                <ul class="data_list">
                    <li>
                        <p>收入</p>
                        <p class="red font_big"><span class="small_font">+</span>{{acctDetail.amt_add}}<span class="small_font">元</span></p>
                        <p>共{{acctDetail.num_add}}笔</p>
                    </li>
                    <li>
                        <p>支出</p>
                        <p class="green font_big"><span class="small_font">-</span>{{acctDetail.amt_sub}}<span class="small_font">元</span></p>
                        <p>共{{acctDetail.num_sub}}笔</p>
                    </li>
                    <li>
                        <p>余额</p>
                        <p class="font_big">{{acctDetail.amt_end}}<span class="small_font">元</span></p>
                        <p class="tips red">ps:待提现，不包括手续费和服务费</p>
                    </li>
                </ul>
            </div>
            
        </div>

        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="450">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="expend_amt" label="支出金额"></el-table-column>
            <el-table-column prop="shop_name" label="支出门店"></el-table-column>
            <el-table-column prop="expend_type" label="支出类型"> </el-table-column>
            <el-table-column prop="expend_time" label="支出时间"> </el-table-column>
            <el-table-column prop="acct_time" label="到账时间"> </el-table-column>
        </el-table>
        <Pagination :limit="returnNumber(listForm.page_size)" :currentPage="returnNumber(listForm.page_index)" :total="dataTotal" @size-change="handleSizeChange" @tabPageCurrent="handleCurrentChange" />
    </div>
</template>
<script>
import Pagination from '../../../Pagination'
import {query_acct_expend_list} from '../../../../api/store'
export default {
  props: {
      shop_id: {type: String | Number, default:()=>''},
      acctDetail: {type: Object, default: ()=>{return {}}}
  },
  components: {Pagination},
  data() {
    return {
      active: 1,
      tableData: [],
      listForm: {
        ent_id: this.$store.state.ent_id,
        shop_id: this.$store.state.user_id,
        page_size: '10',
        page_index: '1',
      },
      dataTotal: 0,
      dataPicker: [],
      searchSelected: {},
      searchOptions: {
          type: [
            {id: '2', label: '手续费'}
          ]
      }
    }
  },
  created() {
      this.initSearchSelected()
      this.getDataList()
  },
  methods: {
      async getDataList() {
        let params = {data: this.listForm}
        let res = await query_acct_expend_list(params)
        if(res && res.code == 200) {
            if(res.body.data.list && res.body.data.list.length > 0) this.tableData = res.body.data.list
            this.dataTotal = res.body.data.total
        } else {
            this.$message({message: res.msg, type: 'error'})
        }
      },
    getDatePicker(v) {
        if(v && v.length > 0) {
            this.searchSelected.date_start = v[0]
            this.searchSelected.date_end = v[1]
        }
        this.searchListBtn()
    },
    //  publick
    initSearchSelected() {
      this.searchSelected = {
        date_start: '',
        date_end: '',
        search_str: '',
        expend_type: ''
      }
    },
    // 分页方法
    handleSizeChange(e) {
      this.listForm.page_index = '1'
      this.listForm.page_size = String(e)
      this.getDataList()
    },
    handleCurrentChange(e) {
      this.listForm.page_index = String(e)
      this.getDataList()
    },
    // 搜索方法
    searchListBtn() {
      for (let key in this.searchSelected) {
        
        this.listForm[key] = this.searchSelected[key];
      }
      this.listForm.page_index = '1'
      this.getDataList();
    },
    resetSearchParams() {
      for (let key in this.searchSelected) {
        this.searchSelected[key] = null;
        delete this.listForm[key];
      }
      this.dateList = []
      this.getDataList();
    },
    returnNumber(v) {
      return Number(v)
    }
  }

}
</script>
<style lang="scss" scoped>
.nav_data{ padding: 15px; background: #fff; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; margin-bottom: 20px;
    .search_form{ display: flex;
        .search_item{ margin-right: 25px; 
            .search_text{ width: 300px; }
        }
    }
    .tips{ color: red; font-size: 12px; text-align: right;}
    

    .data_box{ 
        .data_list{display: flex; padding: 15px 0; justify-items: center; justify-content: space-around;
            li{ font-size: 14px; 
                p{ text-align: center; line-height: 25px;}
                .red{ color: red;}
                .green{ color: green; }
                .font_big{ font-size: 16px; font-weight: bold; }
                .small_font{ font-size: 12px; font-weight: lighter; }
            }
        }
    }
}
</style>