<!-- // earl 2021-09-07 -->
<template>
    <div class="groupb_uy">

        <div class="rules_set">
            <div class="MarketingCampaign-title"><span></span>设置分销收益</div>
            <div class="set_content">
                <el-form label-width="0">
                    <el-form-item>
                        <p>
                            <span class="text">选择店铺</span>
                            <shopListByEntId :ent_id="$store.state.ent_id" @getShopInfo="getShopInfo" @getAllShopList="getAllShopList"></shopListByEntId>
                        </p>
                        <p>
                            <span class="text">A购买后分享（可提现或储值）至B（多个），并且B产生购买，购买后A获得该笔订单分销比例为</span>
                            <el-input type="number" min="0" v-model="setSubmitForm.rate_a" style="width: 180px;">
                                <template slot="append">%</template>
                            </el-input>
                        </p>
                        <p>
                            <span class="text">B购买后分享（可提现或储值）至C（多个），并且C产生购买，购买后A获得该笔订单分销比例为</span>
                            <el-input type="number" min="0" v-model="setSubmitForm.rate_a2" style="width: 180px;">
                                <template slot="append">%</template>
                            </el-input>
                            <span class="text">，且B获得该笔订单分销比例为</span>
                            <el-input type="number" min="0" v-model="setSubmitForm.rate_b2" style="width: 180px;">
                                <template slot="append">%</template>
                            </el-input>
                        </p>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">点击立即生效</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="table_box">
            <div class="MarketingCampaign-title"><span></span>分销明细</div>
            <div class="nav_data">
                <div class="search_select">
                    <form ref="searchForm" class="search_form" :model="searchSelected" label-width="0px">
                        <div class="search_item">
                            <el-date-picker v-model="dataPicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getDatePicker"></el-date-picker>
                        </div>
                        <div class="search_item">
                            <el-input placeholder="请输入内容" class="search_text" v-model="searchSelected.search_str" @input="searchListBtn">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                        </div>
                    </form>
                </div>
            </div>

            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="450">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="pay_type" label="支付类型">
                    <template slot-scope="scope">{{getStatusLabel(scope.row.pay_type, pay_type_list(), 'id', 'label')}}</template>
                </el-table-column>
                <el-table-column prop="tuan_order_id" label="团购订单编号"> </el-table-column>
                <el-table-column prop="trans_id" label="团购支付流水号"> </el-table-column>
                <el-table-column prop="mobile" label="团购成功客户ID"> </el-table-column>
                <el-table-column prop="pay_amt" label="团购金额(元)"> </el-table-column>
                <el-table-column prop="retail_level" label="分销人等级"> </el-table-column>
                <el-table-column prop="retail_user_id" label="分销人ID">
                    <template slot-scope="scope"><span @click="goTo('')" class="member_id">{{scope.row.retail_user_id}}</span></template>
                </el-table-column>
                <el-table-column prop="retail_amt" label="分销金额(元)"> </el-table-column>
                <!-- <el-table-column prop="retail_rate" label="分销比例%"> </el-table-column> -->
                <el-table-column prop="retail_time" label="分销成功时间"> </el-table-column>
                <el-table-column prop="retail_acct_time" label="分销到账时间"> </el-table-column>
                <!-- <el-table-column prop="retail_acct_type" label="分销到账类型"> </el-table-column> -->
            </el-table>
            <Pagination :limit="returnNumber(listForm.page_size)" :currentPage="returnNumber(listForm.page_index)" :total="dataTotal" @size-change="handleSizeChange" @tabPageCurrent="handleCurrentChange" />
        </div>
        
    </div>
</template>
<script>
import Pagination from '../../../Pagination'
import shopListByEntId from '../../../shopList/shopListByEntId.vue'
import {query_group_buy_retail_list, insert_retail_set, query_retail_set} from '../../../../api/store'
export default {
  name: 'groupBuy',
  data() {
    return {
      active: 1,
      tableData: [],
      listForm: {
        ent_id: this.$store.state.ent_id,
        page_size: '10',
        page_index: '1',
      },
      dataTotal: 0,
      searchSelected: {},
      dataPicker: [],
      setSubmitForm: {
          ent_id: this.$store.state.ent_id,
          shop_id: '',
          rate_a2: '',
          rate_b2: ''
      }
    }
  },
  components: {Pagination,shopListByEntId},
  created() {
    this.initSearchSelected()
    this.dataPicker = [this.$moment(Date.now()).format('YYYY-MM-DD'), this.$moment(Date.now()).format('YYYY-MM-DD')]
    this.getDatePicker(this.dataPicker)
  },
  methods: {
      async getDataList() {
        let params = {data:this.listForm}, res
        res = await query_group_buy_retail_list(params)
        if(res && res.code == 200) {
            this.tableData = res.body.data.list
            this.dataTotal = res.body.data.total
        } else {
            this.$message({message: res.msg, type: 'error'})
        }
      },
      async onSubmit() {
          if(!this.setSubmitForm.shop_id) {
              this.$message({message: '请先选择店铺', type: 'warning'})
              return
          }
          let params = {data: this.setSubmitForm}
          let res = await insert_retail_set(params)
          if(res && res.code == 200) {
              this.$message({message: '恭喜您，设置已生效！', type: 'success'})
          } else {
              this.$message({message: res.msg, type: 'error'})
          }
      },
      async getRetailSet(v) {
          let params = {data: {ent_id: this.$store.state.ent_id, shop_id: v,}}
          let res = await query_retail_set(params)
          if(res && res.code == 200) {
              if(!res.body.data || res.body.data=='null') this.setSubmitForm = {ent_id: this.$store.state.ent_id, shop_id: v}
              else this.setSubmitForm = res.body.data
          } else {
              this.$message({message: res.msg, type: 'error'})
          }
      },
      getAllShopList(list) {
          this.getRetailSet(list[0].shop_id)
      },
      getShopInfo(v, item) {
        this.setSubmitForm.shop_id = v
        this.getRetailSet(v)
      },
      getDatePicker(v) {
        this.dataPicker = v
        if(v && v.length > 0) {
            this.searchSelected.date_start = v[0]
            this.searchSelected.date_end = v[1]
        }
        this.searchListBtn()
    },
    goTo(v) {
        this.$message({message: '跳转到会员档案', type: 'error'})
    },
      //  publick
    initSearchSelected() {
      this.searchSelected = {
        date_start: '',
        date_end: '',
        search_str: ''
      }
    },
    // 分页方法
    handleSizeChange(e) {
      this.listForm.page_index = '1'
      this.listForm.page_size = String(e)
      this.getDataList()
    },
    handleCurrentChange(e) {
      this.listForm.page_index = String(e)
      this.getDataList()
    },
    // 搜索方法
    searchListBtn() {
      for (let key in this.searchSelected) {
        this.listForm[key] = this.searchSelected[key];
        if(this.searchSelected[key] == 'all') delete this.listForm[key]
      }
      this.listForm.page_index = '1'
      this.getDataList();
    },
    resetSearchParams() {
      for (let key in this.searchSelected) {
        this.searchSelected[key] = null;
        delete this.listForm[key];
      }
      this.dateList = []
      this.getDataList();
    },
    returnNumber(v) {
      return Number(v)
    },
    pay_type_list() {
      return [
        {id: 'all', label: '结算方式(全部)'},
        {id: '1', label: '微信'},
        // {id: '2', label: '储值卡'},
        // {id: '3', label: '挂账'},
        // {id: '4', label: '现金'},
        {id: '5', label: 'POS-刷卡'},
        {id: '6', label: 'POS-微信'},
        {id: '7', label: 'POS-支付宝'},
        {id: '8', label: 'pos-银联扫码'}
      ]
    },
    getStatusLabel(v, list, key, label) {
        for(let i=0; i<list.length; i++) {
          if(v==list[i][key]) {
            return list[i][label]
          }
        }
      }
  }

}
</script>
<style lang="scss" scoped>
.MarketingCampaign-title { padding: 23px 0 0 20px; font-size: 18px; font-family: Source Han Sans CN; font-weight: 400; display: flex; justify-content: left; align-items: center; font-size: 20px; font-family: Source Han Sans CN; font-weight: 400; color: #333333; margin-bottom: 12px;
    >span { display: inline-block; width: 6px; height: 30px; background: linear-gradient(221deg, #fc4353, #ff5e41); border-radius: 3px; margin-right: 11px;}
}
.rules_set{background: #fff; border-radius: 10px; margin-bottom: 25px;
    .set_content{ padding: 15px;
        p{ display: flex; margin-bottom: 15px; 
            .text{ margin: 0 3px; }
        }
        p:last-child{ margin-bottom: 0; }
    }
}
.member_id{ color: #FC4353; cursor: pointer; }
.table_box{ background: #fff; border-radius: 10px;}
.nav_data{ padding: 15px;
    .search_form{ display: flex;
        .search_item{ margin-right: 25px; 
            .search_text{ width: 300px; }
        }
    }
    .tips{ color: red; font-size: 12px; text-align: right;}
    

    .data_box{ 
        .data_list{display: flex; padding: 15px 0; justify-items: center; justify-content: space-around;
            li{ font-size: 14px; 
                p{ text-align: center; line-height: 25px;}
                .red{ color: red;}
                .green{ color: green; }
                .font_big{ font-size: 16px; font-weight: bold; }
                .small_font{ font-size: 12px; font-weight: lighter; }
            }
        }
    }
}
</style>