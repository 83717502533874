<!-- // earl 2021-09-07 -->
<template>
    <div class="groupb_uy">
        <div class="nav_data">
            <div class="search_select">
                <el-form ref="searchForm" class="search_form" label-width="110px">
                    <el-form-item :label="'账户'+ (index+1)+':'" v-for="(item, index) in currentAcc" :key="index">
                        <div class="ok_item" v-if="!item.isEdit">
                            <span>{{item.bank_name}}</span>，
                            <span>{{item.open_bank}}</span>，
                            <span>{{item.bank_acct}}</span>
                            <i class="el-icon-edit del_btn" @click="editItem(index, item)"> 编辑</i>
                            <i class="el-icon-delete del_btn" @click="delItem(index, item)"> 删除</i>
                        </div>
                        <div v-else>
                            <el-input placeholder="开户行" v-model="item.open_bank" style="width: 280px"></el-input>
                            <el-input placeholder="银行名称" v-model="item.bank_name" style="width: 100px"></el-input>
                            <el-input placeholder="银行卡号" v-model="item.bank_acct" style="width: 260px"></el-input>
                            <i class="el-icon-check del_btn" @click="okItem(item, index)"> 完成</i>
                            <i class="el-icon-delete del_btn" @click="delItem(index, item)"> 删除</i>
                        </div>
                        
                    </el-form-item>
                    <el-form-item label="">
                        <i class="el-icon-plus add_btn" @click="addItem"> 新增提现用户</i>
                        <i class="el-icon-setting set_btn" @click="setReflect"> 设置提现</i>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="450">
            <el-table-column prop="shop_id" type="selection" width="55"> </el-table-column>
            <el-table-column prop="shop_name" label="当前店铺"></el-table-column>
            <el-table-column prop="cash_acct" label="提现账户"> </el-table-column>
            <!-- <el-table-column prop="" label="到账时间"> </el-table-column> -->
            <el-table-column prop="cash_amt" label="提现金额（元）"> </el-table-column>
            <el-table-column prop="cash_type" label="到账时间(类型)">
                <template slot-scope="scope">
                    {{scope.row.cash_type == 1 ? '立即到账' : '隔天到账'}}
                </template>
            </el-table-column>
        </el-table>

        <Pagination :limit="returnNumber(listForm.page_size)" :currentPage="returnNumber(listForm.page_index)" :total="dataTotal" @size-change="handleSizeChange" @tabPageCurrent="handleCurrentChange" />

        <el-dialog title="提现设置" :visible.sync="dialogVisible" width="1000px">
            <el-form ref="reflectForm" class="search_form" label-width="110px">
                <el-form-item label="选择提现店铺">
                    <shopListByEntId :ent_id="$store.state.ent_id" :selected_id="reflectForm.shop_id" @getShopInfo="getShopInfo"></shopListByEntId>
                </el-form-item>
                <el-form-item label="选择提现账户">
                    <el-select v-model="reflectForm.cash_acct" placeholder="请选择">
                        <el-option v-for="item in currentAcc" :key="item.acct_id" :label="item.bank_name + item.bank_acct" :value="item.acct_id"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="提现金额">
                     <el-input placeholder="提现金额（元）" v-model="reflectForm.cash_amt" style="width: 200px"></el-input>
                </el-form-item> -->
                <el-form-item label="到账时间">
                    <el-radio-group v-model="reflectForm.cash_type">
                        <el-radio :label="'1'">自动结算T1</el-radio>
                        <el-radio :label="'2'">自动结算(天天结)</el-radio>
                        <el-radio :label="'3'">定时结算</el-radio>
                    </el-radio-group>
                    <span v-if="reflectForm.cash_type==3" style="margin-left: 30px;">每天8点到23点</span>
                     <!-- <el-date-picker v-model="reflectForm.cash_type" type="datetime" placeholder="选择日期时间"></el-date-picker> -->
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmitReflect">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import Pagination from '../../../Pagination'
import {query_cash_acct_list,insert_cash_acct,update_cash_acct,delete_cash_acct, query_cash_set_list, insert_cash_set, query_group_buy_retail_list} from '../../../../api/store'
import {if_text_valid} from '../../../../api/public'
import shopListByEntId from '../../../shopList/shopListByEntId.vue'
export default{
  name: 'groupBuy',
  data() {
    return {
      active: 1,
      currentAcc: [],
      dataPicker: [],
      tableData: [],
      listForm: {
        ent_id: this.$store.state.ent_id,
        page_size: '10',
        page_index: '1',
      },
      dataTotal: 0,
    //   tableData: [],
      dialogVisible: false,
      reflectForm: {},
      editType: ''
    }
  },
  components:{shopListByEntId, Pagination},
  created() {
      this.init()
      this.initForm()
      this.getDataList()
  },
  methods: {
    async getDataList() {
        let params = {data:this.listForm}, res
        res = await query_cash_set_list(params)
        if(res && res.code == 200) {
            this.tableData = res.body.data.list
            this.dataTotal = res.body.data.total
            console.log('this.tableData', this.tableData)
        } else {
            this.$message({message: res.msg, type: 'error'})
        }
    },
    delItem(index, item) {
        let __this = this
        this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
          if(!item.acct_id) {
            __this.currentAcc.splice(index, 1)
          } else {
            __this.deleteAcct(item)
          }
        }).catch(() => {
          this.$message({type: 'info', message: '已取消删除'});          
        });
    },
    editItem(index, item) {
        this.editType = 'edit'
        this.$set(this.currentAcc[index], 'isEdit', true)
    },
    addItem() {
        this.editType = 'add'
        this.currentAcc.push({ent_id: this.$store.state.ent_id, bank_name: '', open_bank: '', bank_acct: '', isEdit: true})
    },
    async deleteAcct(item) {
        let params = {data:{ent_id: this.$store.state.ent_id, acct_id: item.acct_id}}, res
        res = await delete_cash_acct(params)
        if(res && res.code == 200) {
            this.init()
            this.$message({type: 'success', message: '删除成功!'});
        }
    },
    async okItem(item, index) {
        if(!item.open_bank) {
           this.$message({message: '请输入开户行信息', type: 'error'});
            return 
        }
        if(!item.bank_name) {
           this.$message({message: '请输入银行名称', type: 'error'});
            return 
        }
        if(!item.bank_acct) {
           this.$message({message: '请输入银行卡号', type: 'error'});
            return 
        }
        let banknameVerifi = await if_text_valid({data: {text: item.bank_name}})
        if(banknameVerifi.code == 1100) {
            this.$message({message: '银行名称包含违规内容', type: 'error'});
            return
        }
        let openBankVerifi = await if_text_valid({data: {text: item.open_bank}})
        if(openBankVerifi.code == 1100) {
            this.$message({message: '开户行包含违规内容', type: 'error'});
            return
        }
        
        let params = {data:item}, res
        if(this.editType == 'edit') res = await update_cash_acct(params)
        else if(this.editType == 'add') res = await insert_cash_acct(params)

        if(res && res.code == 200) {
            this.$message({message: '恭喜您，编辑成功!', type: 'success'})
            this.init()
            this.$set(this.currentAcc[index], 'isEdit', false)
        } else {
            this.$message({message: res.msg, type: 'error'})
        }
        
    },
    // 提现设置
    async setReflect() {
        // let params = {data:{ent_id: this.$store.state.ent_id}}, res;
        // res = await query_cash_set_list(params)
        // if(res && res.code == 200) {
            
        // }
        this.initForm()
        this.dialogVisible = true
        
    },
    async onSubmitReflect() {
        let params = {data:this.reflectForm}
        let res = await insert_cash_set(params)
        if(res && res.code == 200) {
            this.$message({message: '恭喜您，提现成功!', type: 'success'})
            this.initForm()
            this.dialogVisible = false
        } else {
            this.$message({message: res.msg, type: 'error'})
        }
    },
    // 获取选中的店铺
    getShopInfo(id, item) {
        this.reflectForm.shop_id = id
    },
    async init() {
        // 获取提现账户列表
        let params = {data:{ent_id: this.$store.state.ent_id}}, data = []
        let res = await query_cash_acct_list(params)
        if(res && res.code == 200) {
            this.currentAcc = res.body.data
        } else {
            this.$message({message: res.msg, type: 'error'})
        }
    },
    initForm(){
        this.reflectForm = {
            ent_id: this.$store.state.ent_id,
            user_id: this.$store.state.user_id,
            shop_id: '',
            cash_acct: '',
            cash_amt: '',
            cash_type: ''
        }
    },
    //  publick
    initSearchSelected() {
      this.searchSelected = {
        date_start: '',
        date_end: '',
        search_str: '',
        status_inv: ''
      }
    },
    // 分页方法
    handleSizeChange(e) {
      this.listForm.page_index = '1'
      this.listForm.page_size = String(e)
      this.getDataList()
    },
    handleCurrentChange(e) {
      this.listForm.page_index = String(e)
      this.getDataList()
    },
    // 搜索方法
    searchListBtn() {
      for (let key in this.searchSelected) {
        
        this.listForm[key] = this.searchSelected[key];
      }
      this.listForm.page_index = '1'
      this.getDataList();
    },
    resetSearchParams() {
      for (let key in this.searchSelected) {
        this.searchSelected[key] = null;
        delete this.listForm[key];
      }
      this.dateList = []
      this.getDataList();
    },
    returnNumber(v) {
      return Number(v)
    }
    
  }

}
</script>
<style lang="scss" scoped>
.nav_data{ padding: 15px; background: #fff; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; margin-bottom: 25px;
    .search_form{
        .el-form-item{ margin-bottom: 0; }
        .el-input{ margin-right: 10px; }
        .del_btn{ color: #e33c4b; margin-left: 30px; cursor: pointer;}
        .add_btn{ color: #e33c4b; border: solid #e33c4b 1px; border-radius: 6px; padding: 12px 20px; box-sizing: border-box; font-size: 14px; cursor: pointer; margin-right: 15px;}
        
    }
    .tips{ color: red; font-size: 12px; text-align: right;}
    
    .data_box{ 
        .data_list{display: flex; padding: 15px 0; justify-items: center; justify-content: space-around;
            li{ font-size: 14px; 
                p{ text-align: center; line-height: 25px;}
                .red{ color: red;}
                .green{ color: green; }
                .font_big{ font-size: 16px; font-weight: bold; }
                .small_font{ font-size: 12px; font-weight: lighter; }
            }
        }
    }
}
.set_btn{ color: #fff; border: solid #e33c4b 1px; background: #e33c4b; border-radius: 6px; padding: 12px 20px; box-sizing: border-box; font-size: 14px; cursor: pointer;}
</style>