<!-- // earl 2021-09-07 -->
<template>
    <div class="payment_setting">
        <div class="toggle">
            <ul>    
              <li @click="active=1" :class="active==1 ? 'current_ele' : ''"><p>商家</p></li>
              <!-- <li @click="active=2" :class="active==2 ? 'current_ele' : ''"><p>提现方式</p></li> -->
              <li @click="active=3" :class="active==3 ? 'current_ele' : ''"><p>团购分销</p></li>
            </ul>
        </div>

        <store-comp v-if="active==1"></store-comp>
        <!-- <reflect-comp v-if="active==2"></reflect-comp> -->
        <group-buy-comp v-if="active==3"></group-buy-comp>

    </div>
</template>
<script>
import storeComp from './components/store.vue'
import reflectComp from './components/reflect.vue'
import groupBuyComp from './components/groupBuy.vue'

export default{
  name: 'paymentSetting',
  data() {
    return {
      active: 1
    }
  },
  components: { storeComp, reflectComp, groupBuyComp},
  methods: {
    
  }

}
</script>
<style lang="scss" scoped>
.el-main {
    background: #f2f6fc;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
  }
.toggle { font-size: 15px; color: #333; display: flex; height: 80px; display: block; flex-flow: column nowrap; align-items: center; margin-bottom: 30px; -moz-box-shadow: 0px 0px 10px #e8e8eb; -webkit-box-shadow: 0px 0px 10px #e8e8eb; box-shadow: 0px 0px 10px #e8e8eb;
  & > ul { width: 100%; height: 80px; background: #ffffff; border-radius: 10px; flex: 1; display: flex; justify-content: space-around;
    li { color: #333333; line-height: 80px;
      &:hover { cursor: pointer;}
    }
  }
  .current_ele { color: #fc4353; position: relative; text-shadow: 3px 6px 3px rgba(252, 80, 95, 0.4);
    &:before { content: ''; width: 100%; height: 5px; border-radius: 3px; background: #fc4353; position: absolute; display: block; left: 0; bottom: 0;}
  }
}

</style>